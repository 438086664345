import { template as template_439c15c8d50d4146897becee8352b7e5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_439c15c8d50d4146897becee8352b7e5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
