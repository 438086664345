import { template as template_2e8b0035b6d9448db709163bd380df27 } from "@ember/template-compiler";
const FKText = template_2e8b0035b6d9448db709163bd380df27(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
