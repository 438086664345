import { template as template_d8f689b69cd149719a754e3af1e56394 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d8f689b69cd149719a754e3af1e56394(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
