import { template as template_ca55d03b5584411892fdd87f00d220cc } from "@ember/template-compiler";
const EmptyState = template_ca55d03b5584411892fdd87f00d220cc(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
